/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVisualFilters, selectEmbedToken } from 'modules/dashboard/selectors';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import {
  selectIsCumulative,
  selectIsSegmentDetailed,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectTrendTimeWeeklyView,
  selectRevenueIsDisplayTS,
  selectTrendSegmentTabularVisuals,
  selectTrendSegmentCumulativeTabularVisuals,
  selectTrendSegmentWeeklyTabularVisuals,
  selectTrendSegmentPercentageTabularVisuals,
  selectIsPercentageChange,
  selectTrendHotelNormalizedView,
  selectTrendSegmentTabularFilterList,
  selectTrendHotelFocusOn,
  selectActiveTrendTab,
  selectIsMovingAverage,
  selectMovingAverageTabularVisualTS,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import { REPORT_TYPE } from 'modules/dashboard/constants';
/**
 * Trend Segment tabular report with filters
 */
const TrendSegmentTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectTrendSegmentTabularFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const visualFilters = useSelector(selectVisualFilters);
  const visuals = useSelector(selectTrendSegmentTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendSegmentWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendSegmentCumulativeTabularVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTS);
  const percentageVisuals = useSelector(selectTrendSegmentPercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const activeTab = useSelector(selectActiveTrendTab);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverageSegmentTabularVisuals = useSelector(selectMovingAverageTabularVisualTS);
  //
  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedRendered, setIsTabularCombinedRendered] = useState({});

  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = cumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (weeklyViewToggle) {
      found = weeklyVisuals?.find((visual) => visual?.order === index);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = percentageVisuals?.find((visual) => visual?.order === index);
    } else if (isMovingAverage && !weeklyViewToggle && !isPercentageChange) {
      found = movingAverageSegmentTabularVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsRendered = () => {},
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsRendered={(d) => setIsRendered(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
  const breakdown = `Breakdown : , ${selectedBreakdown}\r\n`;
  const focus = focusOn ? focusOn?.map((item) => item?.label).toString() : '';
  const focusedBreakdown = `Focused Breakdown Segments: , "${focus.toString()}"\r\n`;
  //
  return (
    <VisualGrid
      isDisplay={revenueIsDisplay}
      visualName={getVisualName(0)}
      visual={getWidget(0, styles.tabularVisual, setTabularCombined, setIsTabularCombinedRendered)}
      onClick={() => {
        dispatch(trendActions.setRevenueIsDisplayTS(!revenueIsDisplay));
      }}
      toggleFragment={
        <ExportToCSV
          report={tabularCombined}
          isLoaded={isTabularCombinedRendered}
          fileName={
            isPercentageChange
              ? 'TVS_Percentage'
              : isMovingAverage
              ? 'CMATS_Daily'
              : isCumulative
              ? 'TVS_Cumulative'
              : weeklyViewToggle
              ? 'TVS_Weekly'
              : 'TVS_Daily'
          }
          weeklyViewToggle={weeklyViewToggle}
          isCumulative={isCumulative}
          otherFilters={breakdown.concat(focusedBreakdown)}
        />
      }
      isWorkbookHotelComparison={activeTab === REPORT_TYPE.TREND_HOTEL}
      comparisonHotel={hotelFocusOn}
      displayHotelName
    />
  );
};
//
export default TrendSegmentTabularWidget;
