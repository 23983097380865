import { useEffect, useRef } from 'react';
import { AuthWrapper, PrivateRoute } from 'modules/common/auth/components';
import {
  ADMIN_ROLE,
  DEMO_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLE,
  PREFERRED_ROLE,
  PREFERRED_ADMIN_ROLE,
} from 'modules/common/constants/roles';
import ROUTES from 'modules/common/constants/route';
import AuthLayout from 'modules/common/layouts/auth';
import DashboardLayout from 'modules/common/layouts/dashboard';
import { EventCategoriesConfigureView } from 'modules/event-configuration/components';
import { DashboardView } from 'modules/dashboard/components';
import Page404 from 'modules/error-pages/404';
import { ForgotPasswordView } from 'modules/forgot-password/components';
import { RateCalendarUploadView } from 'modules/rate-calendar-upload/components';
import { RateCalendarView } from 'modules/rate-calendar/components';
import { ResetPasswordView } from 'modules/reset-password/components';
import { UsersView } from 'modules/users/components';
import VerifyCallback from 'modules/verify-callback/components';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HotelRoomsConfigureView } from 'modules/hotel-configuration/components';
import { SignUpView } from 'modules/sign-up/components';
import {
  EXECUTION_TYPE,
  UiController,
  consoleCommands,
  decodedTabs,
} from 'modules/common/ui-controller';
import { ENVIRONMENT } from 'config';
import { executeMultipleCommands } from 'modules/common/ui-controller/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelChangeState,
  selectTabChangeState,
  selectUserRole,
  selectVisualFilters,
} from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { transformIntoArray } from 'modules/common/utils/array';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import SignInAuthWindow from 'modules/sign-in/components/auth-window';
import SignInLayout from 'modules/common/layouts/sign-in';
import { SignInView } from 'modules/sign-in/components';
import UserManual from 'modules/user-manual/components';
import FAQView from 'modules/faq/components';
import SupportView from 'modules/support/components';

/**
 * Define the routeing structure using array. Here include the nested routeing as well.
 * And also define the auth wrapper and private routers for helping to role based routes
 */
const setRoutes = () => {
  // get current path location
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  // reference to execute ui controller command with search value
  const searchCommandsRef = useRef(null);
  // global selectors fot tab and hotel name change
  const tabChange = useSelector(selectTabChangeState);
  const hotelChange = useSelector(selectHotelChangeState);
  const userRole = useSelector(selectUserRole);
  const visualFilters = useSelector(selectVisualFilters);
  // function ref to execute ui controller commands after checking encryption
  searchCommandsRef.current = () => {
    // retrieve search string from url
    const decode = ENVIRONMENT.ENCRYPTION === '1' ? atob(location?.search) : location?.search;
    // execute url filter options
    executeMultipleCommands(decode, EXECUTION_TYPE.URL);
  };
  // triggers when location path changes
  useEffect(() => {
    // check location hash and set the tab
    if (location?.hash && visualFilters) {
      dispatch(dashboardActions.setTabChange(false));
      dispatch(dashboardActions.setUrlOrder(0));
      const tab = decodedTabs[location?.hash.substring(1)];
      if (tab !== undefined) {
        if (isAuthenticated()) {
          if (
            tab === decodedTabs['i-prefer'] &&
            (userRole === USER_ROLE || userRole === DEMO_ROLE)
          ) {
            navigate(ROUTES.DASHBOARD);
            return;
          }
          UiController.setTab(tab);
        }
      }
    }
    // retrieve hotel command and set hotel name
    if (location?.search && visualFilters) {
      dispatch(dashboardActions.setHotelChange(false));
      const data = location?.search.substring(1);
      const commands = data?.split('&');
      const hotel = commands?.filter((com) => com.includes(consoleCommands.HOTEL));
      const hotelGroup = commands?.filter((com) => com.includes(consoleCommands.HOTEL_GROUP));
      if (hotel?.length > 0) {
        // assign hotel name value into array
        const values = transformIntoArray(hotel[0]?.split('=')[1]);
        UiController.setHotelName(values);
      } else if (hotelGroup?.length > 0) {
        const groupValues = transformIntoArray(hotelGroup[0]?.split('=')[1]);
        UiController.setHotelGroupName(groupValues);
      }
    }
  }, [location, isAuthenticated, visualFilters]);
  // triggers when tab and hotel commands are executed
  useEffect(() => {
    // call for command execution function after tab and hotel commands are executed
    if (tabChange && hotelChange && location?.search) {
      searchCommandsRef.current();
    }
  }, [tabChange, hotelChange]);
  // define route configurations
  const routes = [
    {
      path: ROUTES.ROOT,
      element: <Navigate to={ROUTES.LOGIN} />,
    },
    {
      path: ROUTES.ROOT,
      element: <SignInLayout />,
      children: [
        {
          path: ROUTES.LOGIN,
          element: <SignInView />,
        },
        {
          path: ROUTES.LOGIN_REDIRECT,
          element: <SignInAuthWindow />,
        },
      ],
    },
    {
      path: ROUTES.ROOT,
      element: <AuthLayout />,
      children: [
        {
          path: ROUTES.FORGOT_PASSWORD,
          element: <ForgotPasswordView />,
        },
        {
          path: ROUTES.RESET_PASSWORD,
          element: <ResetPasswordView />,
        },
        {
          path: ROUTES.SIGN_UP,
          element: <SignUpView />,
        },
      ],
    },
    {
      path: ROUTES.DASHBOARD,
      element: (
        <AuthWrapper>
          <DashboardLayout />
        </AuthWrapper>
      ),
      children: [
        {
          path: '',
          hash: location.hash,
          search: location.search ?? '',
          element: (
            <PrivateRoute
              component={DashboardView}
              roles={[
                SUPER_ADMIN_ROLE,
                ADMIN_ROLE,
                USER_ROLE,
                DEMO_ROLE,
                PREFERRED_ROLE,
                PREFERRED_ADMIN_ROLE,
              ]}
            />
          ),
        },
        ENVIRONMENT.ENABLE_BULK_UPLOAD.toLowerCase() === 'true' && {
          path: ROUTES.FORECAST_UPLOAD,
          element: (
            <PrivateRoute
              component={RateCalendarUploadView}
              roles={[
                SUPER_ADMIN_ROLE,
                ADMIN_ROLE,
                USER_ROLE,
                DEMO_ROLE,
                PREFERRED_ROLE,
                PREFERRED_ADMIN_ROLE,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: ROUTES.RATE_CALENDAR,
      element: (
        <AuthWrapper>
          <DashboardLayout />
        </AuthWrapper>
      ),
      children: [
        {
          path: '',
          element: (
            <PrivateRoute
              component={RateCalendarView}
              roles={[
                SUPER_ADMIN_ROLE,
                ADMIN_ROLE,
                USER_ROLE,
                DEMO_ROLE,
                PREFERRED_ROLE,
                PREFERRED_ADMIN_ROLE,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: ROUTES.CONFIGURE,
      element: (
        <AuthWrapper>
          <DashboardLayout />
        </AuthWrapper>
      ),
      children: [
        {
          path: ROUTES.CONFIGURE_HOTELS,
          element: (
            <PrivateRoute
              component={HotelRoomsConfigureView}
              roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]}
            />
          ),
        },
        {
          path: ROUTES.EVENT_CATEGORIES,
          element: (
            <PrivateRoute
              component={EventCategoriesConfigureView}
              roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]}
            />
          ),
        },
        {
          path: ROUTES.USERS,
          element: (
            <PrivateRoute
              component={UsersView}
              roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE, PREFERRED_ADMIN_ROLE]}
            />
          ),
        },
      ],
    },
    {
      path: ROUTES.NOT_FOUND,
      element: <Page404 />,
    },
    {
      path: ROUTES.VERIFY_CALLBACK,
      element: <VerifyCallback />,
    },
    {
      path: ROUTES.USER_MANUAL,
      element: <UserManual />,
    },
    {
      path: ROUTES.FAQ,
      element: <FAQView />,
    },
    {
      path: ROUTES.SUPPORT,
      element: <SupportView />,
    },
  ];
  return routes;
};
//
export default setRoutes;
